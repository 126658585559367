@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'sky';
  src: url('../font/sky.woff2') format('woff2'),
    url('../font/sky.woff') format('woff'),
    url('../font/sky.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'sky';
  src: url('../font/sky-bold.woff2') format('woff2'),
    url('../font/sky-bold.woff') format('woff'),
    url('../font/sky-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.grad_bg {
  background: rgb(246, 246, 246);
  background: linear-gradient(
    112deg,
    rgba(246, 246, 246, 1) 93%,
    rgba(217, 237, 247, 1) 100%
  );
}

.greenish_bg {
  background: rgb(183, 236, 216);
  background: linear-gradient(
    63deg,
    rgba(183, 236, 216, 1) 0%,
    rgba(246, 246, 246, 1) 10%
  );
}

.padd {
  padding-right: 2rem /* 16px */;
  padding-left: 2rem /* 16px */;
}

@media (min-width: 768px) {
  .padd {
    padding-right: 4rem /* 32px */;
    padding-left: 4rem /* 32px */;
  }
}

html,
body {
  font-family: 'sky', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.mobile_menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5.2rem /* 32px */;
  left: 0;
  right: 0;
  background-color: #bdc3c7;
  z-index: 100;
  padding: 1rem 0px;
}
